import type { AUTO_SAVING_ANIMATION_STATUS, IProject } from '@/services/ide/projects.service'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useProjectManager = defineStore('projectManager', () => {
  const generatedProjectName = ref<string>('')
  const isAwaitsOpenFlag = ref<boolean>(false)
  const selectedProject = ref<any | null>(null)
  const projectPermissionWithId = ref<IProject | null>(null)
  const collaboratorList = ref<IProject[] | null>(null)
  const comingFromProjectManager = ref<boolean>(false)
  const showAutoSavingStatus = ref<AUTO_SAVING_ANIMATION_STATUS | null>(null)

  const isSelectedProject = computed(() => {
    return selectedProject.value
  })

  /**
   * @param value new generated project or reset
   */
  const setGeneratedProjectName = (value: string) => {
    generatedProjectName.value = value
  }
  /**
   * Set the open editor
   * @param value - The value to set
   */
  const setIsAwaitsOpenFlag = (value: boolean) => {
    isAwaitsOpenFlag.value = value
  }
  /**
   * Set selected project
   * @param data - The data to set
   */
  const setSelectedProject = (data: any | null) => {
    selectedProject.value = data
  }

  /**
   * @param value - setting the id and status of the project that is opened
   */
  const setProjectPermissionWithId = (value: IProject) => {
    projectPermissionWithId.value = value
  }
  /**
   * Set to open project manager modal
   * @param value - The value to set the project manager modal
   */
  const setComingFromProjectManager = (value: boolean) => {
    comingFromProjectManager.value = value
  }
  /**
   * Show the auto save enabled popup accordingly
   * @param value - set the value for auto save enabled
   */
  const setShowAutoSavingStatus = (value: AUTO_SAVING_ANIMATION_STATUS | null) => {
    showAutoSavingStatus.value = value
  }

  /**
   * @param value - setting the list of the collaborator
   */
  const setCollaboratorList = (value: IProject[]) => {
    collaboratorList.value = value
  }

  return {
    generatedProjectName,
    setGeneratedProjectName,
    isSelectedProject,
    setSelectedProject,
    isAwaitsOpenFlag,
    setIsAwaitsOpenFlag,
    setProjectPermissionWithId,
    projectPermissionWithId,
    collaboratorList,
    setCollaboratorList,
    comingFromProjectManager,
    setComingFromProjectManager,
    showAutoSavingStatus,
    setShowAutoSavingStatus
  }
})
